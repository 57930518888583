import DashboardIcon from "@mui/icons-material/Dashboard";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import DrawerTabType from "@paperdateco/shared-frontend/components/layout/drawer/DrawerTabType";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import ResponsiveDrawer from "@paperdateco/shared-frontend/components/layout/ResponsiveDrawer";
import logo from "@paperdateco/shared-frontend/assets/images/logo/small_logo.png";
import routes from "@paperdateco/collaborator/routes/routes";

export const PortalTabs: DrawerTabType[] = [
  {
    id: "dashboard",
    icon: DashboardIcon,
    label: "Dashboard",
    route: routes.DASHBOARD,
  },
  {
    id: "designs",
    icon: DesignServicesIcon,
    label: "Designs",
    route: routes.DESIGNS,
  },
  {
    id: "library",
    icon: PhotoLibraryIcon,
    label: "Library",
    route: routes.LIBRARY_IMAGES,
  },
  {
    id: "logout",
    icon: ExitToAppIcon,
    label: "Logout",
    route: routes.LOGOUT,
  },
];

export default function PortalPage() {
  return (
    <ResponsiveDrawer
      tabs={PortalTabs}
      defaultRoute={routes.DASHBOARD}
      logo={logo}
    >
      Paper Date Co. Collaborator Panel
    </ResponsiveDrawer>
  );
}
