import { saveAs } from "file-saver";

export default class FileUtils {
  static openFileDialog = (
    callback: any,
    accept: string = ".jpg,.gif,.svg,.png,.pdf,.mp4"
  ) => {
    var inputElement = document.createElement("input");
    inputElement.type = "file";
    inputElement.accept = accept; // Note Edge does not support this attribute
    inputElement.addEventListener("change", callback);

    inputElement.dispatchEvent(new MouseEvent("click"));
  };

  static saveCsvFile(content: string, fileName: string) {
    var blob = new Blob([content], { type: "text/csv;charset=utf-8" });
    saveAs(blob, fileName);
  }
}
