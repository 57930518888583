import {
  Button,
  CircularProgress,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { FormEvent, useEffect, useState } from "react";

import { Box } from "@mui/system";
import Colors from "@paperdateco/shared-frontend/utils/styles/Colors";
import CustomNumberInput from "../../common/form/CustomNumberInput";
import CustomTextField from "../../common/form/CustomTextField";
import DesignConstants from "@paperdateco/common/utils/constants/DesignConstants";
import DesignDto from "@paperdateco/common/dto/design/DesignDto";

const PopupContainer = styled(Box)({
  background: Colors.WHITE,
});

interface DesignNamePopupProps {
  design?: DesignDto;
  onSave?: (name: string, width: number, height: number) => Promise<void>;
  onClose?: () => void;
}

export default function DesignNamePopup({
  design,
  onSave,
  onClose,
}: DesignNamePopupProps) {
  const [name, setName] = useState("");
  const [width, setWidth] = useState(DesignConstants.DEFAULT_WIDTH);
  const [height, setHeight] = useState(DesignConstants.DEFAULT_HEIGHT);

  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (design) {
      setName(design.name);
      setWidth(design.width);
      setHeight(design.height);
    }
  }, [design]);

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setDisabled(true);
    await onSave?.(name, width, height);
    setDisabled(false);
    onClose?.();
  };

  return (
    <PopupContainer padding={4}>
      <Typography variant="h5" marginBottom={2}>
        Save Design
      </Typography>
      <form onSubmit={onSubmit}>
        <Stack spacing={2} alignItems="center" minWidth={300}>
          <CustomTextField
            value={name}
            onChange={setName}
            type="text"
            label="Design Name"
          />
          <CustomNumberInput
            value={width}
            onChange={setWidth}
            label="Width"
            inputProps={{ step: 0.01 }}
          />
          <CustomNumberInput
            value={height}
            onChange={setHeight}
            label="Height"
            inputProps={{ step: 0.01 }}
          />
          <Button
            type="submit"
            variant="contained"
            disabled={disabled}
            endIcon={disabled && <CircularProgress size={20} />}
          >
            {design ? "UPDATE" : "ADD"}
          </Button>
        </Stack>
      </form>
    </PopupContainer>
  );
}
