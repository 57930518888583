import { useEffect, useState } from "react";

import { BoxProps } from "@mui/material";
import CanvasPageProvider from "@paperdateco/shared-frontend/canvas/provider/CanvasPageProvider";
import DesignDto from "@paperdateco/common/dto/design/DesignDto";
import DesignFormLayout from "./DesignFormLayout";
import DesignPageDto from "@paperdateco/common/dto/design/pages/DesignPageDto";
import DesignRequestDto from "@paperdateco/common/dto/design/DesignRequestDto";

interface DesignFormProps {
  design?: DesignDto;
  onSave?: (design: DesignRequestDto) => Promise<void>;
}

export default function DesignForm({
  design,
  onSave,
  ...props
}: DesignFormProps & BoxProps) {
  const [pages, setPages] = useState<DesignPageDto[]>([]);

  useEffect(() => {
    setPages(design?.pages ?? [new DesignPageDto({ layers: [] })]);
  }, [design]);

  return (
    <CanvasPageProvider pages={pages}>
      <DesignFormLayout design={design} onSaveDesign={onSave} {...props} />
    </CanvasPageProvider>
  );
}
