import {
  DesignStateDispatchType,
  getAllDesigns,
} from "@paperdateco/collaborator/store/design/DesignActions";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import DesignLibraryApi from "@paperdateco/common/api/design/DesignLibraryApi";
import LibraryImageDto from "@paperdateco/common/dto/design/library/image/LibraryImageDto";
import LibraryImageFormPage from "@paperdateco/shared-frontend/pages/libraryImage/LibraryImageFormPage";
import NotificationUtils from "@paperdateco/common/utils/NotificationUtils";
import { getAllLibraryImages } from "@paperdateco/collaborator/store/libraryImage/LibraryImageAction";
import routes from "@paperdateco/collaborator/routes/routes";
import { selectDesigns } from "@paperdateco/collaborator/store/design/DesignSelector";

export default function LibraryImageEditPage() {
  const { imageId } = useParams<{ imageId: string }>();
  const [libraryImage, setLibraryImage] = useState<LibraryImageDto>();
  const dispatch = useDispatch<DesignStateDispatchType>();
  const navigate = useNavigate();
  const designs = useSelector(selectDesigns);

  useEffect(() => {
    if (!imageId) {
      return;
    }
    dispatch(getAllDesigns());
    DesignLibraryApi.getLibraryImage(imageId)
      .then(setLibraryImage)
      .catch(NotificationUtils.showGenericError);
  }, [imageId, dispatch]);

  const onSubmit = useCallback(() => {
    dispatch(getAllLibraryImages(true));
    navigate(routes.LIBRARY_IMAGES);
  }, [dispatch, navigate]);

  return (
    <LibraryImageFormPage
      designs={designs}
      libraryImage={libraryImage}
      onSubmit={onSubmit}
      backLink={routes.LIBRARY_IMAGES}
    />
  );
}
