import { Box, Typography, styled } from "@mui/material";

import Colors from "@paperdateco/shared-frontend/utils/styles/Colors";
import DesignDto from "@paperdateco/common/dto/design/DesignDto";
import LibraryImageDto from "@paperdateco/common/dto/design/library/image/LibraryImageDto";
import LibraryImageForm from "./LibraryImageForm";
import LibraryImageType from "@paperdateco/common/dto/design/library/image/LibraryImageType";

const Container = styled(Box)({
  background: Colors.WHITE,
});

interface LibraryImageFormModalProps {
  designs?: DesignDto[];
  design?: DesignDto;
  isAdmin?: boolean;
  libraryImage?: LibraryImageDto;
  defaultTypes?: LibraryImageType[];
  onSubmit?: (libraryImage: LibraryImageDto) => void;
}

export default function LibraryImageFormModal({
  designs = [],
  design,
  isAdmin,
  libraryImage,
  defaultTypes,
  onSubmit,
}: LibraryImageFormModalProps) {
  return (
    <Container
      minWidth={350}
      margin={2}
      padding={3}
      maxHeight="80vh"
      overflow="auto"
    >
      <Typography variant="h6">
        {libraryImage
          ? `Update ${libraryImage.name}`
          : `Add new ${defaultTypes?.[0]}`}
      </Typography>
      <LibraryImageForm
        designs={designs}
        defaultDesign={design}
        isAdmin={isAdmin}
        libraryImage={libraryImage}
        defaultTypes={defaultTypes}
        onSubmit={onSubmit}
      />
    </Container>
  );
}
