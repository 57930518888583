import DesignImageComponent from "./DesignImageComponent";
import LibraryImageDto from "../../library/image/LibraryImageDto";

export default class DesignImageComponentDto {
  image: LibraryImageDto;
  url: string;
  price: number;

  constructor(image: DesignImageComponent) {
    this.image = new LibraryImageDto(image.image);
    this.url = image.url;
    this.price = image.price;
  }
}
