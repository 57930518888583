import type {} from "@mui/lab/themeAugmentation";

import { CSSProperties } from "react";
import Colors from "@paperdateco/shared-frontend/utils/styles/Colors";
import { createStyled } from "@mui/system";
import { createTheme } from "@mui/material";

declare module "@mui/material/styles" {
  interface Palette {
    border: {
      primary?: CSSProperties["color"];
      secondary?: CSSProperties["color"];
    };
    customBackground: {
      light?: CSSProperties["color"];
    };
  }
  interface PaletteOptions {
    border: {
      primary?: CSSProperties["color"];
      secondary?: CSSProperties["color"];
    };
    customBackground: {
      light?: CSSProperties["color"];
    };
  }

  interface TypographyVariants {
    body3: CSSProperties;
  }
  interface TypographyVariantsOptions {
    body3?: CSSProperties;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    body3: true;
  }
}

const DefaultTheme = createTheme({
  palette: {
    primary: {
      main: Colors.PRIMARY,
      light: Colors.PRIMARY_LIGHT,
      "600": Colors.PRIMARY_600,
      "900": Colors.PRIMARY_900,
    },
    secondary: {
      main: Colors.SECONDARY,
    },
    background: {
      default: Colors.WHITE,
      paper: Colors.SNOW_WHITE,
    },
    text: {
      primary: Colors.DARK_GREY,
      secondary: Colors.PRIMARY,
    },
    border: {
      primary: Colors.BLACK,
      secondary: Colors.LIGHT_GREY,
    },
    error: {
      main: Colors.RED,
    },
    customBackground: {
      light: Colors.LIGHT_GREY,
    },
  },
  typography: {
    fontSize: 16,
    h3: {
      fontWeight: 500,
      fontSize: "2.5rem",
    },
    h4: {
      fontWeight: 600,
      fontSize: "2rem",
    },
    h5: {
      fontWeight: 600,
      fontSize: "1.5rem",
    },
    h6: {
      fontSize: "1.125rem",
    },
    body1: {
      fontSize: "1rem",
    },
    body2: {
      fontSize: "0.875rem",
    },
    body3: {
      fontSize: "0.75rem",
    },
    subtitle1: {
      fontSize: "1rem",
    },
    fontFamily: "inherit",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          color: Colors.BLACK,
          fontFamily: "Roboto",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: 6,
          fontSize: "1rem",
          lineHeight: "1.2rem",
        },
        outlinedPrimary: {
          borderWidth: 2,
          "&:hover": {
            borderWidth: 2,
          },
        },
        sizeLarge: {
          padding: "12px 30px",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: Colors.DARK_GREY,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          padding: 14,
          "&:hover .MuiOutlinedInput-notchedOutline": {
            boxShadow: `0px 4px 4px ${Colors.BLACK}25`,
          },
        },
        input: {
          padding: 0,
        },
        notchedOutline: {
          borderColor: Colors.DARK_GREY,
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          background: Colors.WHITE,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        colorPrimary: {
          color: Colors.PRIMARY,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginBottom: 0,
        },
      },
    },
  },
});

export const CustomStyled = createStyled({ defaultTheme: DefaultTheme });

export default DefaultTheme;
