import DesignComponents from "./DesignComponents";
import DesignImageComponentDto from "./image/DesignImageComponentDto";

export default class DesignComponentsDto {
  background?: DesignImageComponentDto;
  frontEnvelope?: DesignImageComponentDto;
  backEnvelope?: DesignImageComponentDto;
  innerEnvelope?: DesignImageComponentDto;
  coverEnvelope?: DesignImageComponentDto;

  constructor(components: DesignComponents) {
    this.background =
      components.background &&
      new DesignImageComponentDto(components.background);
    this.frontEnvelope =
      components.frontEnvelope &&
      new DesignImageComponentDto(components.frontEnvelope);
    this.backEnvelope =
      components.backEnvelope &&
      new DesignImageComponentDto(components.backEnvelope);
    this.innerEnvelope =
      components.innerEnvelope &&
      new DesignImageComponentDto(components.innerEnvelope);
    this.coverEnvelope =
      components.coverEnvelope &&
      new DesignImageComponentDto(components.coverEnvelope);
  }
}
