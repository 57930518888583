import {
  AppBar,
  AppBarProps,
  Box,
  IconButton,
  Toolbar,
  Typography,
  styled,
} from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";

const CustomAppBar = styled(AppBar)(({ theme }) => ({
  background: theme.palette.background.paper,
  color: theme.palette.primary.main,
  boxShadow:
    "0px 0px 2px -1px rgb(0 0 0 / 5%), 0px 1px 3px 0px rgb(0 0 0 / 5%), 0px 1px 3px 0px rgb(0 0 0 / 5%)",
  borderBottom: `1px solid ${theme.palette.border.primary}`,
}));

interface PortalAppBarProps {
  onDrawerToggle?: () => void;
}

export default function PortalAppBar({
  onDrawerToggle,
  children,
  ...props
}: PortalAppBarProps & AppBarProps) {
  return (
    <CustomAppBar position="sticky" {...props}>
      <Toolbar>
        <Typography variant="h5" noWrap flexGrow={1}>
          {children}
        </Typography>
        <Box marginLeft={0.5} display={{ xs: "block", sm: "none" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={onDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
        </Box>
      </Toolbar>
    </CustomAppBar>
  );
}
