import CollaboratorDto from "../product/collaborator/CollaboratorDto";
import DesignComponentsDto from "./components/DesignComponentsDto";
import DesignConstants from "@paperdateco/common/utils/constants/DesignConstants";
import DesignLayerDto from "./layer/DesignLayerDto";
import DesignModel from "./DesignModel";
import DesignPageDto from "./pages/DesignPageDto";

export default class DesignDto {
  id: string;
  name: string;
  width: number;
  height: number;
  pages: DesignPageDto[];
  layers: DesignLayerDto[];
  components: DesignComponentsDto;
  preview?: string;
  designer?: CollaboratorDto;

  constructor(design: DesignModel, isPopulated = true) {
    if (!isPopulated) {
      this.id = design._id.toString();
      return;
    }
    this.id = design.id;
    this.name = design.name;
    this.width = design.width ?? DesignConstants.DEFAULT_WIDTH;
    this.height = design.height ?? DesignConstants.DEFAULT_HEIGHT;
    this.pages = design.pages?.map((page) => new DesignPageDto(page)) ?? [];
    this.layers = design.layers?.map((l) => new DesignLayerDto(l));
    this.components = new DesignComponentsDto(design.components);
    this.preview = design.preview;
    this.designer =
      design.createdBy &&
      new CollaboratorDto(
        design.createdBy,
        design.populated("createdBy") ?? false
      );
    this.patchPagesForOldDesigns();
  }

  patchPagesForOldDesigns() {
    if (this.pages.length !== 0) {
      return;
    }
    const defaultPage: DesignPageDto = {
      layers: this.layers,
      background: this.components.background,
      preview: this.preview,
    };
    this.pages.push(defaultPage);
  }
}
