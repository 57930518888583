import {
  FormControl,
  FormControlProps,
  FormGroup,
  FormLabel,
} from "@mui/material";

import CustomCheckbox from "./CustomCheckbox";

export interface CustomCheckboxOptionType<T> {
  name: string;
  value: T;
}

interface CustomCheckboxGroupProps<T> {
  label: string;
  options: CustomCheckboxOptionType<T>[];
  value: T[];
  onChange: (value: T[]) => void;
}

export default function CustomCheckboxGroup<T>({
  label,
  options,
  value,
  onChange,
  ...props
}: CustomCheckboxGroupProps<T> & Omit<FormControlProps, "onChange">) {
  const onCheckboxChange = (option: T) => (checked: boolean) => {
    const newValue = value.filter((r) => r !== option);
    if (checked) {
      newValue.push(option);
    }
    onChange(newValue);
  };

  return (
    <FormControl variant="standard" {...props}>
      <FormLabel component="legend">{label}</FormLabel>
      <FormGroup>
        {options.map((option) => (
          <CustomCheckbox
            key={option.name}
            label={option.name}
            checked={value.includes(option.value)}
            onChange={onCheckboxChange(option.value)}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
}
