import Column from "../../table/Column";
import DesignDto from "@paperdateco/common/dto/design/DesignDto";

enum DesignFieldNames {
  ID = "id",
  NAME = "name",
  IMAGE = "image",
  LAYERS = "layers",
}

const DesignColumns: { [key in DesignFieldNames]: Column<DesignDto> } = {
  id: { title: "ID", field: "id" },
  name: { title: "Name", field: "name" },
  image: {
    title: "Image",
    field: "cards",
    render: (design) => (
      <img
        src={design.preview ?? design.components.background?.url}
        alt={design.name}
        width={100}
      />
    ),
  },
  layers: {
    title: "Layers",
    field: "layers",
    render: (design) => design.layers.length,
  },
};

export default DesignColumns;
