import AnimationType from "@paperdateco/common/canvas/animation/AnimationType";
import DesignAnimationComponent from "./DesignAnimationComponent";
import LibraryAnimationDto from "../../library/animation/LibraryAnimationDto";

export default class DesignAnimationComponentDto {
  animation: LibraryAnimationDto;
  animationType: AnimationType;
  price: number;

  constructor(animation: DesignAnimationComponent) {
    this.animation = new LibraryAnimationDto(animation.animation);
    this.animationType = animation.animationType;
    this.price = animation.price;
  }
}
