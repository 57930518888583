import Column from "../../table/Column";
import LibraryImageDto from "@paperdateco/common/dto/design/library/image/LibraryImageDto";

enum LibraryImageFieldNames {
  ID = "id",
  NAME = "name",
  TYPES = "types",
  IMAGE = "image",
  PRICE = "price",
  TAGS = "tags",
}

const LibraryImageColumns: {
  [key in LibraryImageFieldNames]: Column<LibraryImageDto>;
} = {
  id: { title: "ID", field: "id" },
  name: { title: "Name", field: "name" },
  types: {
    title: "Categories",
    field: "types",
    render: (item) => item.types.join(", "),
  },
  image: {
    title: "Image",
    field: "image",
    render: (item) => <img src={item.url} alt={item.name} width={100} />,
  },
  price: { title: "Price", field: "price" },
  tags: {
    title: "Tags",
    field: "tags",
    render: (item) => item.tags.join(", "),
  },
};

export default LibraryImageColumns;
