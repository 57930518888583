import { Navigate, useLocation } from "react-router-dom";
import { useCallback, useContext, useEffect } from "react";

import { AuthContext } from "@paperdateco/shared-frontend/components/auth/AuthProvider";
import AuthLayout from "@paperdateco/shared-frontend/components/layout/AuthLayout";
import { Box } from "@mui/material";
import Colors from "@paperdateco/shared-frontend/utils/styles/Colors";
import { CustomStyled } from "@paperdateco/collaborator/utils/styles/DefaultTheme";
import LoginForm from "@paperdateco/shared-frontend/components/auth/LoginForm";
import LoginPageState from "@paperdateco/shared-frontend/components/auth/LoginPageState";
import NotificationUtils from "@paperdateco/common/utils/NotificationUtils";
import UserDto from "@paperdateco/common/dto/user/UserDto";
import UserRole from "@paperdateco/common/dto/auth/UserRole";
import routes from "@paperdateco/collaborator/routes/routes";

const LoginFormContainer = CustomStyled(Box)({
  border: `1px solid ${Colors.LIGHT_GREY}`,
});

export default function CollaboratorLoginForm() {
  const { user } = useContext(AuthContext);
  const { state }: { state: LoginPageState } = useLocation();

  const onLogin = useCallback((user: UserDto) => {
    if (!user) {
      return;
    } else if (!user.roles.includes(UserRole.COLLABORATOR)) {
      NotificationUtils.showError("You do not have access to the site");
    }
  }, []);

  useEffect(() => {
    if (state?.accessDenied) {
      NotificationUtils.showError("You do not have access to the site");
    }
  }, [state]);

  if (user && user.roles.includes(UserRole.COLLABORATOR)) {
    return <Navigate to={routes.PORTAL} />;
  }

  return (
    <AuthLayout width="100vw">
      <LoginFormContainer width="90%" maxWidth={450} margin="auto" padding={4}>
        <LoginForm title="Login to Collaborator Portal" onLogin={onLogin} />
      </LoginFormContainer>
    </AuthLayout>
  );
}
