import {
  DesignStateDispatchType,
  getAllDesigns,
} from "@paperdateco/collaborator/store/design/DesignActions";

import DesignEditPage from "@paperdateco/shared-frontend/pages/design/DesignEditPage";
import { getAllLibraryImages } from "@paperdateco/collaborator/store/libraryImage/LibraryImageAction";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import useListenLibraryUpdate from "@paperdateco/shared-frontend/components/design/section/library/useListenLibraryUpdate";

export default function CollaboratorDesignEditPage() {
  const dispatch = useDispatch<DesignStateDispatchType>();
  const onUpdateDesign = useCallback(() => {
    dispatch(getAllDesigns(true));
  }, [dispatch]);

  const onUpdateLibrary = useCallback(() => {
    dispatch(getAllLibraryImages(true));
  }, [dispatch]);

  useListenLibraryUpdate(onUpdateLibrary);

  return <DesignEditPage onUpdate={onUpdateDesign} />;
}
