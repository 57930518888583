import DesignDto from "@paperdateco/common/dto/design/DesignDto";
import LibraryImageDto from "@paperdateco/common/dto/design/library/image/LibraryImageDto";
import LibraryImageType from "@paperdateco/common/dto/design/library/image/LibraryImageType";
import LibraryImageFormModal from "@paperdateco/shared-frontend/pages/libraryImage/LibraryImageFormModal";
import MessageConstants from "@paperdateco/shared-frontend/utils/MessageConstants";
import { useContext } from "react";
import { MessageContext } from "../../providers/MessageProvider";
import { ModalContext } from "../../providers/ModalProvider";

export default function useNewLibraryImage(
  design?: DesignDto,
  onSubmit?: () => void
) {
  const { open, close } = useContext(ModalContext);
  const { send } = useContext(MessageContext);

  const onSubmitForm = (libraryImage: LibraryImageDto) => {
    onSubmit?.();
    send(MessageConstants.LIBRARY_IMAGES, libraryImage);
    close();
  };

  const onAddNewLibraryImage = (type: LibraryImageType) => {
    open(
      <LibraryImageFormModal
        design={design}
        defaultTypes={[type]}
        onSubmit={onSubmitForm}
      />
    );
  };

  return onAddNewLibraryImage;
}
