import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";

import CollaboratorDesignAddPage from "../components/pages/design/CollaboratorDesignAddPage";
import CollaboratorDesignEditPage from "../components/pages/design/CollaboratorDesignEditPage";
import CollaboratorLoginForm from "../components/auth/CollaboratorLoginForm";
import CollaboratorRoute from "@paperdateco/shared-frontend/routes/guards/CollaboratorRoute";
import DesignPage from "../components/pages/design/DesignPage";
import DesignsListPage from "../components/pages/design/DesignsListPage";
import HomePage from "../components/pages/HomePage";
import LibraryImageAddPage from "../components/pages/libraryImage/LibraryImageAddPage";
import LibraryImageEditPage from "../components/pages/libraryImage/LibraryImageEditPage";
import LibraryImageListPage from "../components/pages/libraryImage/LibraryImageListPage";
import LibraryImagePage from "../components/pages/libraryImage/LibraryImagePage";
import LogoutPage from "../components/auth/LogoutPage";
import { ModalContext } from "@paperdateco/shared-frontend/components/providers/ModalProvider";
import PortalPage from "../components/pages/PortalPage";
import ScrollToTop from "@paperdateco/shared-frontend/routes/ScrollToTop";
import routes from "./routes";
import { useContext } from "react";

export default function AppRouter() {
  const { popup } = useContext(ModalContext);
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route
          path={routes.PORTAL}
          element={
            <CollaboratorRoute redirectTo={routes.LOGIN}>
              <PortalPage />
            </CollaboratorRoute>
          }
        >
          <Route path={routes.DASHBOARD} element={<HomePage />} />
          <Route path={routes.DESIGNS} element={<DesignPage />}>
            <Route path={routes.DESIGNS} element={<DesignsListPage />} />
            <Route
              path={routes.DESIGN_ADD}
              element={<CollaboratorDesignAddPage />}
            />
            <Route
              path={routes.DESIGN_EDIT()}
              element={<CollaboratorDesignEditPage />}
            />
          </Route>
          <Route path={routes.LIBRARY_IMAGES} element={<LibraryImagePage />}>
            <Route
              path={routes.LIBRARY_IMAGES}
              element={<LibraryImageListPage />}
            />
            <Route
              path={routes.LIBRARY_IMAGES_ADD}
              element={<LibraryImageAddPage />}
            />
            <Route
              path={routes.LIBRARY_IMAGES_EDIT()}
              element={<LibraryImageEditPage />}
            />
          </Route>
          <Route path={routes.LOGOUT} element={<LogoutPage />} />
        </Route>
        <Route path={routes.LOGIN} element={<CollaboratorLoginForm />} />
        <Route path="*" element={<Navigate to={routes.LOGIN} replace />} />
      </Routes>
      {popup}
    </Router>
  );
}
