import DesignImageComponentDto from "../components/image/DesignImageComponentDto";
import DesignLayerDto from "../layer/DesignLayerDto";
import DesignPageModel from "./DesignPageModel";

export default class DesignPageDto {
  id?: string;
  layers: DesignLayerDto[];
  background?: DesignImageComponentDto;
  preview?: string;

  constructor(page: DesignPageModel) {
    this.id = page._id?.toString();
    this.layers = page.layers.map((layer) => new DesignLayerDto(layer));
    this.background =
      page.background && new DesignImageComponentDto(page.background);
    this.preview = page.preview;
  }
}
