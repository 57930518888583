import Table, {
  Option,
} from "@paperdateco/shared-frontend/components/table/Table";

import Colors from "@paperdateco/shared-frontend/utils/styles/Colors";
import Column from "@paperdateco/shared-frontend/components/table/Column";
import LibraryImageColumns from "@paperdateco/shared-frontend/components/design/table/LibraryImageColumns";
import LibraryImageDto from "@paperdateco/common/dto/design/library/image/LibraryImageDto";
import { useCallback } from "react";

const columns: Column<LibraryImageDto>[] = [
  LibraryImageColumns.name,
  LibraryImageColumns.types,
  LibraryImageColumns.image,
  LibraryImageColumns.price,
  LibraryImageColumns.tags,
];

const allColumns = Object.values(LibraryImageColumns);

const LibraryImageTableOptions: Option<LibraryImageDto> = {
  rowStyle: (row: LibraryImageDto) => ({
    backgroundColor: row.visible ? Colors.WHITE : Colors.LIGHT_RED,
  }),
};

interface LibraryImageListTableProps {
  title: string;
  items: LibraryImageDto[];
  onEditItem?: (item: LibraryImageDto) => void;
}

export default function LibraryImageListTable({
  title,
  items,
  onEditItem,
}: LibraryImageListTableProps) {
  const onRowClick = useCallback(
    (_: any, item?: LibraryImageDto) => {
      if (item?.id) {
        onEditItem?.(item);
      }
    },
    [onEditItem]
  );

  return (
    <Table
      title={title}
      data={items}
      columns={columns}
      allColumns={allColumns}
      onRowClick={onRowClick}
      options={LibraryImageTableOptions}
    />
  );
}
