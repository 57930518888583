const PORTAL = "/portal";
const LOGIN = "/";
const DASHBOARD = PORTAL + "/dashboard";
const DESIGNS = PORTAL + "/designs";
const DESIGN_ADD = DESIGNS + "/new";
const DESIGN_EDIT = (designId = ":designId") =>
  DESIGNS + "/" + designId + "/edit";
const LIBRARY_IMAGES = PORTAL + "/library/images";
const LIBRARY_IMAGES_ADD = LIBRARY_IMAGES + "/add";
const LIBRARY_IMAGES_EDIT = (imageId = ":imageId") =>
  LIBRARY_IMAGES + "/edit/" + imageId;
const LOGOUT = PORTAL + "/logout";

const routes = {
  PORTAL,
  LOGIN,
  DASHBOARD,
  DESIGNS,
  DESIGN_ADD,
  DESIGN_EDIT,
  LIBRARY_IMAGES,
  LIBRARY_IMAGES_ADD,
  LIBRARY_IMAGES_EDIT,
  LOGOUT,
};

export default routes;
