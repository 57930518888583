import DashboardContentLayout from "@paperdateco/shared-frontend/components/layout/DashboardContentLayout";
import { Typography } from "@mui/material";

export default function HomePage() {
  return (
    <DashboardContentLayout>
      <Typography variant="body1">Welcome to Collaborator Dashboard</Typography>
    </DashboardContentLayout>
  );
}
