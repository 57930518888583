import { useCallback, useContext } from "react";

import { BoxProps } from "@mui/material";
import { CanvasPageContext } from "@paperdateco/shared-frontend/canvas/provider/CanvasPageProvider";
import CanvasUtils from "@paperdateco/shared-frontend/canvas/CanvasUtils";
import CustomDesignApi from "@paperdateco/common/api/CustomDesignApi";
import CustomDesignEditLeftSidebar from "./custom/CustomDesignEditLeftSidebar";
import DesignConstants from "@paperdateco/common/utils/constants/DesignConstants";
import DesignDto from "@paperdateco/common/dto/design/DesignDto";
import DesignEditFooterSection from "./section/DesignEditFooterSection";
import DesignEditHeaderSection from "./section/DesignEditHeaderSection";
import DesignEditLayout from "./layout/DesignEditLayout";
import DesignEditRightSidebar from "./section/DesignEditRightSidebar";
import DesignRequestDto from "@paperdateco/common/dto/design/DesignRequestDto";
import DesignSaveSection from "./section/DesignSaveSection";
import DesignUtils from "@paperdateco/common/utils/DesignUtils";
import InvitePaginatedInstantPreview from "@paperdateco/shared-frontend/canvas/controls/page/InvitePaginatedInstantPreview";
import useInviteSelectedLayers from "@paperdateco/shared-frontend/canvas/hooks/useInviteSelectedLayers";

interface DesignFormLayoutProps {
  design?: DesignDto;
  onSaveDesign?: (design: DesignRequestDto) => Promise<void>;
}

export default function DesignFormLayout({
  design,
  onSaveDesign,
  ...props
}: DesignFormLayoutProps & BoxProps) {
  const { pages, instantPreview, setPreviewZoom } =
    useContext(CanvasPageContext);
  const selectedLayers = useInviteSelectedLayers(instantPreview);

  const savePreviewImage = useCallback(
    (image: string) =>
      CustomDesignApi.uploadInvitationImage(design?.id ?? "new-design", image),
    [design]
  );

  const onSave = async (n: string, w: number, h: number) => {
    if (!instantPreview) {
      return;
    }
    const designPreviewDataUri = CanvasUtils.takePicture(
      instantPreview.canvas,
      instantPreview.nativeCanvas
    );
    const preview = await savePreviewImage(designPreviewDataUri);

    const pageRequest = await Promise.all(
      pages.map((page) => page.getRequestDto(savePreviewImage))
    );
    const newDesign: DesignRequestDto = {
      name: n,
      width: w,
      height: h,
      preview,
      pages: pageRequest,
      layers: DesignUtils.getRequestLayers(
        instantPreview?.getDesignLayers() ?? design?.layers ?? []
      ),
      components: instantPreview.getComponents(),
    };

    await onSaveDesign?.(newDesign);
  };

  return (
    <DesignEditLayout
      leftSidebar={
        <CustomDesignEditLeftSidebar
          design={design}
          instantPreview={instantPreview}
          selectedLayers={selectedLayers}
        />
      }
      rightSidebar={
        <DesignEditRightSidebar
          instantPreview={instantPreview}
          selectedLayers={selectedLayers}
        />
      }
      header={
        <DesignEditHeaderSection
          defaultTitle={
            design ? `Update "${design.name}" design` : "Add new design"
          }
          selectedLayers={selectedLayers}
        />
      }
      footer={<DesignEditFooterSection onZoom={setPreviewZoom} />}
      canvas={
        <InvitePaginatedInstantPreview
          width={design?.width ?? DesignConstants.DEFAULT_WIDTH}
          height={design?.height ?? DesignConstants.DEFAULT_HEIGHT}
        />
      }
      canvasHeader={<DesignSaveSection design={design} onSave={onSave} />}
      height="100%"
      {...props}
    />
  );
}
