import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

interface BackButtonProps {
  link: string;
  name: string;
}

export default function BackButton({ link, name }: BackButtonProps) {
  return (
    <Link to={link}>
      <Button variant="contained" startIcon={<ArrowBackIcon />}>
        Back to {name}
      </Button>
    </Link>
  );
}
