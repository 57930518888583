import { useCallback, useEffect, useState } from "react";

import DesignApi from "@paperdateco/common/api/DesignApi";
import DesignDto from "@paperdateco/common/dto/design/DesignDto";
import DesignForm from "@paperdateco/shared-frontend/components/design/DesignForm";
import DesignRequestDto from "@paperdateco/common/dto/design/DesignRequestDto";
import NotificationUtils from "@paperdateco/common/utils/NotificationUtils";
import { useParams } from "react-router-dom";

interface DesignEditPageProps {
  onUpdate?: () => void;
}

export default function DesignEditPage({ onUpdate }: DesignEditPageProps) {
  const { designId } = useParams<{ designId: string }>();
  const [design, setDesign] = useState<DesignDto>();

  useEffect(() => {
    if (!designId) {
      return;
    }
    DesignApi.getById(designId)
      .then(setDesign)
      .catch(NotificationUtils.showGenericError);
  }, [designId]);

  const onUpdateDesign = useCallback(
    async (newDesignRequest: DesignRequestDto) => {
      if (!design) {
        console.error("No design found");
        return;
      }
      try {
        const newDesign = await DesignApi.updateById(
          design.id,
          newDesignRequest
        );
        setDesign(newDesign);
        onUpdate?.();
      } catch (e) {
        NotificationUtils.showGenericError(e);
      }
    },
    [design, onUpdate]
  );

  return <DesignForm design={design} onSave={onUpdateDesign} />;
}
