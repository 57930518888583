import DesignImageMaskPropertiesDto from "./mask/DesignImageMaskPropertiesDto";
import DesignImagePropertiesDto from "./image/DesignImagePropertiesDto";
import DesignLayer from "./DesignLayer";
import DesignLayerType from "./DesignLayerType";
import DesignTextPropertiesDto from "./text/DesignTextPropertiesDto";

export default class DesignLayerDto {
  type: DesignLayerType;
  textProperties?: DesignTextPropertiesDto;
  imageProperties?: DesignImagePropertiesDto;
  imageMaskProperties?: DesignImageMaskPropertiesDto;
  constructor(layer: DesignLayer) {
    this.type = layer.type;
    this.textProperties =
      layer.textProperties && new DesignTextPropertiesDto(layer.textProperties);
    this.imageProperties =
      layer.imageProperties &&
      new DesignImagePropertiesDto(layer.imageProperties);
    this.imageMaskProperties =
      layer.imageMaskProperties &&
      new DesignImageMaskPropertiesDto(layer.imageMaskProperties);
  }
}
