import CustomTextField from "./CustomTextField";
import { TextFieldProps } from "@mui/material";
import { useCallback } from "react";

interface CustomNumberInputProps {
  onChange?: (value: number) => void;
}

export default function CustomNumberInput({
  onChange,
  ...props
}: Omit<TextFieldProps, "onChange"> & CustomNumberInputProps) {
  const onCustomChange = useCallback(
    (value: string) => {
      onChange?.(+value);
    },
    [onChange]
  );

  return <CustomTextField {...props} onChange={onCustomChange} type="number" />;
}
