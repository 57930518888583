import { Box, styled } from "@mui/system";
import { Drawer, Hidden } from "@mui/material";
import { Outlet, RouteProps } from "react-router-dom";

import AppDrawer from "./drawer/AppDrawer";
import DrawerTabType from "./drawer/DrawerTabType";
import PortalAppBar from "./drawer/PortalAppBar";
import { useState } from "react";

const drawerWidth = 240;

const CustomDrawer = styled(Drawer)({
  ".MuiPaper-root": {
    width: drawerWidth,
  },
});

export interface ResponsiveDrawerProps {
  tabs: DrawerTabType[];
  defaultRoute: string;
  logo: string;
}

export default function ResponsiveDrawer({
  children,
  tabs,
  logo,
}: ResponsiveDrawerProps & RouteProps) {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

  const closeDrawer = () => setMobileOpen(false);

  return (
    <Box display="flex" minHeight="100vh">
      <Box component="nav" width={{ sm: drawerWidth }} flexShrink={{ sm: 0 }}>
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <CustomDrawer
            variant="temporary"
            anchor="right"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <AppDrawer logo={logo} tabs={tabs} onItemClick={closeDrawer} />
          </CustomDrawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <CustomDrawer variant="permanent" open>
            <AppDrawer logo={logo} tabs={tabs} onItemClick={closeDrawer} />
          </CustomDrawer>
        </Hidden>
      </Box>
      <Box
        component="main"
        flexGrow={1}
        display="flex"
        flexDirection="column"
        height="100vh"
        width={`calc(100% - ${drawerWidth}px)`}
      >
        <PortalAppBar>{children}</PortalAppBar>
        <Box width="100%" flexGrow={1} overflow="auto">
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
}
