import {
  LibraryImageStateDispatchType,
  getAllLibraryImages,
} from "@paperdateco/collaborator/store/libraryImage/LibraryImageAction";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import AddNewButton from "@paperdateco/shared-frontend/components/common/form/button/AddNewButton";
import DashboardContentLayout from "@paperdateco/shared-frontend/components/layout/DashboardContentLayout";
import LibraryImageListTable from "@paperdateco/shared-frontend/components/table/custom/LibraryImageListTable";
import { getAllDesigns } from "@paperdateco/collaborator/store/design/DesignActions";
import routes from "@paperdateco/collaborator/routes/routes";
import { selectDesigns } from "@paperdateco/collaborator/store/design/DesignSelector";
import { selectLibraryImages } from "@paperdateco/collaborator/store/libraryImage/LibraryImageSelector";
import useEditLibraryImage from "@paperdateco/shared-frontend/pages/libraryImage/useEditLibraryImage";

export default function LibraryImageListPage() {
  const images = useSelector(selectLibraryImages);
  const designs = useSelector(selectDesigns);
  const dispatch = useDispatch<LibraryImageStateDispatchType>();

  useEffect(() => {
    dispatch(getAllLibraryImages());
    dispatch(getAllDesigns());
  }, [dispatch]);

  const onUpdateImage = useCallback(() => {
    dispatch(getAllLibraryImages(true));
  }, [dispatch]);

  const onEditImage = useEditLibraryImage(designs, false, onUpdateImage);

  return (
    <DashboardContentLayout width="100%">
      <AddNewButton
        position="right"
        link={routes.LIBRARY_IMAGES_ADD}
        name="LIBRARY IMAGE"
        marginBottom={2}
      />
      <LibraryImageListTable
        title="Library Images"
        items={images}
        onEditItem={onEditImage}
      />
    </DashboardContentLayout>
  );
}
