import { Button, Stack, StackProps } from "@mui/material";

import { Link } from "react-router-dom";

interface AddNewButtonProps {
  position: "left" | "right";
  link: string;
  name: string;
}

export default function AddNewButton({
  position,
  link,
  name,
  ...props
}: AddNewButtonProps & Omit<StackProps, "position">) {
  return (
    <Stack
      alignItems={position === "right" ? "flex-end" : "flex-start"}
      width="100%"
      {...props}
    >
      <Link to={link}>
        <Button variant="contained" size="large">
          ADD NEW {name}
        </Button>
      </Link>
    </Stack>
  );
}
