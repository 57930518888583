import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  styled,
} from "@mui/material";

import Colors from "@paperdateco/shared-frontend/utils/styles/Colors";
import DrawerTabType from "./DrawerTabType";
import { NavLink } from "react-router-dom";

const AppNavLink = styled(NavLink)(({ theme }) => ({
  display: "block",
  "&.nav-active": {
    background: theme.palette.primary.main,
    color: Colors.WHITE,
  },
  "& .MuiListItemIcon-root": {
    color: "inherit",
  },
}));

interface AppDrawerProps {
  tabs: DrawerTabType[];
  logo: string;
  onItemClick?: () => void;
}

export default function AppDrawer({ tabs, logo, onItemClick }: AppDrawerProps) {
  return (
    <>
      <Box padding={2}>
        <img src={logo} alt="logo" width={100} />
      </Box>
      <Divider />
      <List>
        {tabs.map((tab, index) => (
          <AppNavLink
            key={index}
            to={tab.route}
            onClick={onItemClick}
            className={({ isActive }) => (isActive ? "nav-active" : "")}
          >
            <ListItem button>
              <ListItemIcon color="inherit">
                <tab.icon color="inherit" />
              </ListItemIcon>
              <ListItemText primary={tab.label} />
            </ListItem>
          </AppNavLink>
        ))}
      </List>
      <Divider />
    </>
  );
}
