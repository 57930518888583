import { Button, Stack, Typography } from "@mui/material";

import UploadTypes from "@paperdateco/common/dto/common/UploadTypes";
import useFileUpload from "../hooks/useFileUpload";

interface CustomMediaPickerProps {
  label?: string;
  url?: string;
  type?: UploadTypes;
  uploadingMessage?: string;
  width?: number;
  onUpload: (file: File) => Promise<void>;
}

export default function CustomMediaPicker({
  label,
  url,
  type,
  uploadingMessage,
  width = 100,
  onUpload,
}: CustomMediaPickerProps) {
  const onAddImage = useFileUpload({ uploadingMessage, onUpload });

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      {label && <Typography variant="body1">{label}</Typography>}
      {url &&
        (type === UploadTypes.VIDEO ? (
          <video key={url} width={width} muted autoPlay controls loop>
            <source src={url}></source>
          </video>
        ) : (
          <img src={url} width={width} />
        ))}
      <Button variant="contained" onClick={onAddImage}>
        {url ? "Change" : "Choose File"}
      </Button>
    </Stack>
  );
}
