import BackButton from "@paperdateco/shared-frontend/components/common/form/button/BackButton";
import DashboardContentLayout from "@paperdateco/shared-frontend/components/layout/DashboardContentLayout";
import DesignDto from "@paperdateco/common/dto/design/DesignDto";
import LibraryImageDto from "@paperdateco/common/dto/design/library/image/LibraryImageDto";
import LibraryImageForm from "./LibraryImageForm";

interface LibraryImageFormPageProps {
  isAdmin?: boolean;
  libraryImage?: LibraryImageDto;
  designs: DesignDto[];
  onSubmit?: (libraryImage: LibraryImageDto) => void;
  backLink: string;
}

export default function LibraryImageFormPage({
  isAdmin,
  libraryImage,
  designs,
  onSubmit,
  backLink,
}: LibraryImageFormPageProps) {
  return (
    <DashboardContentLayout>
      <BackButton link={backLink} name="Images" />
      <LibraryImageForm
        designs={designs}
        isAdmin={isAdmin}
        libraryImage={libraryImage}
        onSubmit={onSubmit}
      />
    </DashboardContentLayout>
  );
}
