import GetItemsReducer from "@paperdateco/shared-frontend/store/getItems/GetItemsReducer";
import { LIBRARY_IMAGE_GET_ITEMS_NAME } from "./LibraryImageTypes";
import LibraryImageDto from "@paperdateco/common/dto/design/library/image/LibraryImageDto";

const LibraryImageReducer = GetItemsReducer<LibraryImageDto[]>(
  LIBRARY_IMAGE_GET_ITEMS_NAME,
  []
);

export default LibraryImageReducer;
