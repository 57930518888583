import DesignDto from "@paperdateco/common/dto/design/DesignDto";
import DesignEditNextButton from "../common/DesignEditNextButton";
import DesignNamePopup from "../custom/DesignNamePopup";
import { ModalContext } from "../../providers/ModalProvider";
import { useContext } from "react";

interface DesignSaveSectionProps {
  design?: DesignDto;
  onSave?: (name: string, width: number, height: number) => Promise<void>;
}

export default function DesignSaveSection({
  design,
  onSave,
}: DesignSaveSectionProps) {
  const { open, close } = useContext(ModalContext);

  const showNamePopup = () => {
    open(<DesignNamePopup design={design} onSave={onSave} onClose={close} />);
  };

  return (
    <DesignEditNextButton variant="contained" onClick={showNamePopup}>
      SAVE
    </DesignEditNextButton>
  );
}
