import DesignReducer from "./design/DesignReducer";
import LibraryImageReducer from "./libraryImage/LibraryImageReducer";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
  design: DesignReducer,
  libraryImages: LibraryImageReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
