import { MouseEvent, useCallback } from "react";

import { useNavigate } from "react-router";

export default function useClickListener<T>(
  fn: (item?: T) => string | undefined
) {
  const navigate = useNavigate();

  return useCallback(
    (event?: MouseEvent, item?: T) => {
      const url = fn(item);
      if (!url) {
        return;
      }
      if (
        event?.ctrlKey ||
        event?.shiftKey ||
        event?.metaKey || // apple
        event?.button === 1
      ) {
        const newWindow = window.open(url, "_blank", "noopener,noreferrer");
        if (newWindow) {
          newWindow.opener = null;
        }
      } else {
        navigate(url);
      }
    },
    [fn, navigate]
  );
}
