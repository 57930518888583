import AnimationType from "@paperdateco/common/canvas/animation/AnimationType";
import LibraryAnimationModel from "./LibraryAnimationModel";

export default class LibraryAnimationDto {
  id: string;
  animationType: AnimationType;
  name: string;
  price: number;
  url: string;
  visible: boolean;

  constructor(item: LibraryAnimationModel) {
    if (typeof item.id !== "string") {
      this.id = item._id.toString();
      return;
    }
    this.id = item.id;
    this.animationType = item.animationType;
    this.name = item.name;
    this.price = item.price;
    this.url = item.url;
    this.visible = item.visible;
  }
}
