import DesignDto from "@paperdateco/common/dto/design/DesignDto";
import LibraryImageDto from "@paperdateco/common/dto/design/library/image/LibraryImageDto";
import LibraryImageFormModal from "./LibraryImageFormModal";
import MessageConstants from "@paperdateco/shared-frontend/utils/MessageConstants";
import { MessageContext } from "@paperdateco/shared-frontend/components/providers/MessageProvider";
import { ModalContext } from "@paperdateco/shared-frontend/components/providers/ModalProvider";
import { useContext } from "react";

export default function useEditLibraryImage(
  designs: DesignDto[],
  isAdmin: boolean,
  onSubmit?: () => void
) {
  const { open, close } = useContext(ModalContext);
  const { send } = useContext(MessageContext);

  const onEditLibraryImage = (libraryImage: LibraryImageDto) => {
    const onSubmitForm = (libraryImage: LibraryImageDto) => {
      onSubmit?.();
      send(MessageConstants.LIBRARY_IMAGES, libraryImage);
      close();
    };
    open(
      <LibraryImageFormModal
        designs={designs}
        isAdmin={isAdmin}
        libraryImage={libraryImage}
        onSubmit={onSubmitForm}
      />
    );
  };

  return onEditLibraryImage;
}
