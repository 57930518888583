import DesignImageComponentDto from "../../components/image/DesignImageComponentDto";
import DesignImageMaskProperties from "./DesignImageMaskProperties";
import DesignLayerPropertiesDto from "../common/DesignLayerPropertiesDto";
import DesignMaskedImagePropertiesDto from "./image/DesignMaskedImagePropertiesDto";

export default class DesignImageMaskPropertiesDto extends DesignLayerPropertiesDto {
  mask: DesignImageComponentDto;
  maskWidth: number;
  maskHeight: number;
  maskedImage?: DesignMaskedImagePropertiesDto;

  constructor(image: DesignImageMaskProperties) {
    super(image);
    this.mask = new DesignImageComponentDto(image.mask);
    this.maskWidth = image.maskWidth;
    this.maskHeight = image.maskHeight;
    this.maskedImage =
      image.maskedImage &&
      new DesignMaskedImagePropertiesDto(image.maskedImage);
  }
}
