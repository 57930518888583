import {
  CSSProperties,
  ForwardRefExoticComponent,
  MouseEvent,
  ReactElement,
  RefAttributes,
  forwardRef,
} from "react";
import MaterialTable, { Components } from "@material-table/core";
import { Theme, ThemeProvider, createTheme } from "@mui/material";

import AddBox from "@mui/icons-material/AddBox";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import Check from "@mui/icons-material/Check";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Clear from "@mui/icons-material/Clear";
import Colors from "@paperdateco/shared-frontend/utils/styles/Colors";
import Column from "./Column";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import Edit from "@mui/icons-material/Edit";
import FilterList from "@mui/icons-material/FilterList";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import Remove from "@mui/icons-material/Remove";
import SaveAlt from "@mui/icons-material/SaveAlt";
import Search from "@mui/icons-material/Search";
import TableUtils from "./TableUtils";
import ViewColumn from "@mui/icons-material/ViewColumn";
import usePersistedState from "../common/hooks/usePersistedState";

export type Icon = ForwardRefExoticComponent<RefAttributes<SVGSVGElement>>;

export interface Icons {
  Add: Icon;
  Check: Icon;
  Clear: Icon;
  Delete: Icon;
  DetailPanel: Icon;
  Edit: Icon;
  Export: Icon;
  Filter: Icon;
  FirstPage: Icon;
  SortArrow: Icon;
  LastPage: Icon;
  NextPage: Icon;
  PreviousPage: Icon;
  ResetSearch: Icon;
  Search: Icon;
  ThirdStateCheck: Icon;
  ViewColumn: Icon;
}

export const TableIcons: Icons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export type TableEvent<RowData extends object> = (
  event: any,
  data: RowData | RowData[]
) => void;

export interface Action<RowData extends object> {
  icon: () => ReactElement<Icon>;
  tooltip: string;
  onClick: TableEvent<RowData>;
  isFreeAction?: boolean;
  disabled?: boolean;
  hidden?: boolean;
}

export interface Option<RowData extends Object> {
  actionsColumnIndex?: number;
  rowStyle?:
    | CSSProperties
    | ((data: RowData, index: number, level: number) => CSSProperties);

  exportAllData?: boolean;
  exportButton?: boolean;
  pageSize?: number;
  pageSizeOptions?: number[];
  headerStyle?: React.CSSProperties;
  search?: boolean;
  searchFieldStyle?: React.CSSProperties;
}

export interface Localization {
  body?: {
    editRow?: {
      saveTooltip?: string;
      cancelTooltip?: string;
      deleteText?: string;
    };
    addTooltip?: string;
    deleteTooltip?: string;
    editTooltip?: string;
  };
}

export interface DetailPanel<RowData extends object> {
  disabled?: boolean;
  icon?: string | React.ComponentType<any>;
  openIcon?: string | React.ComponentType<any>;
  tooltip?: string;
  render: ({ rowData }: { rowData: RowData }) => string | React.ReactNode;
}

export interface TableProps<RowData extends object> {
  id?: string;
  title: string;
  columns: Column<RowData>[];
  allColumns?: Column<RowData>[];
  components?: Components;
  data: RowData[];
  detailPanel?:
    | (({ rowData }: { rowData: RowData }) => React.ReactNode)
    | (DetailPanel<RowData> | ((rowData: RowData) => DetailPanel<RowData>))[];
  actions?: (Action<RowData> | ((rowData: RowData) => Action<RowData>))[];
  isLoading?: boolean;
  options?: Option<RowData>;
  onRowsPerPageChange?: (pageSize: number) => void;
  onRowClick?: (
    event?: MouseEvent,
    rowData?: RowData,
    toggleDetailPanel?: (panelIndex?: number) => void
  ) => void;
  onRowDelete?: (oldData: RowData) => Promise<any>;
  parentChildData?: (row: RowData, rows: RowData[]) => RowData | undefined;
  localization?: Localization;
}

const border = `1px solid ${Colors.LIGHT_BORDER}`;

const TableTheme = createTheme({
  components: {
    MuiToolbar: {
      styleOverrides: {
        root: {
          borderBottom: border,
        },
        regular: {
          minHeight: "unset !important",
          padding: 12,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          border: border,
          borderRadius: 6,
          overflow: "hidden",
        },
        elevation2: {
          boxShadow: "none",
          width: "100%",
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          "& > tr:last-child > *": {
            borderBottom: "none",
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          width: "auto !important",
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        head: {
          "& > *": {
            background: `${Colors.GREY_900} !important`,
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          borderBottom: "none",
          width: "100% !important",
        },
        toolbar: {
          borderTop: border,
          borderBottom: "none",
        },
        spacer: {
          flex: "none",
        },
        input: {
          marginRight: "auto !important",
        },
      },
    },
  },
});

export default function Table<RowData extends object>(
  props: TableProps<RowData>
) {
  const { state: pageSize, setState: setPageSize } = usePersistedState(
    `${props.id ?? props.title}-pagesize`,
    5
  );

  return (
    <ThemeProvider
      theme={(theme: Theme) => ({
        ...theme,
        components: {
          ...theme.components,
          ...TableTheme.components,
        },
      })}
    >
      <MaterialTable
        icons={TableIcons}
        {...props}
        options={{
          exportMenu: [
            {
              label: "Export CSV",
              exportFunc: TableUtils.exportCsv(
                props.data,
                props.allColumns ?? props.columns,
                props.title + ".csv"
              ),
            },
          ],
          exportAllData: true,
          pageSize: pageSize,
          tableLayout: "auto",
          ...props.options,
        }}
        onRowsPerPageChange={setPageSize}
        editable={{ onRowDelete: props.onRowDelete }}
      />
    </ThemeProvider>
  );
}
