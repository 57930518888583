import { useCallback, useEffect, useState } from "react";

import CanvasElement from "@paperdateco/shared-frontend/canvas/objects/CanvasElement";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import Colors from "@paperdateco/shared-frontend/utils/styles/Colors";
import DesignDto from "@paperdateco/common/dto/design/DesignDto";
import DesignEditLeftSection from "../common/types/DesignEditLeftSection";
import DesignEditLeftSidebar from "../section/DesignEditLeftSidebar";
import DesignEditLibrarySection from "../section/library/DesignEditLibrarySection";
import DesignEditTabType from "../common/types/DesignEditTabType";
import DesignEditTextSection from "../section/DesignEditTextSection";
import DesignEditUploadSection from "../section/DesignEditUploadSection";
import InviteInstantPreview from "../../invite/InviteInstantPreview";
import InviteUtils from "@paperdateco/shared-frontend/utils/InviteUtils";
import IosShareIcon from "@mui/icons-material/IosShare";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import useNewLibraryImage from "./useNewLibraryImage";

const LeftTabs: DesignEditTabType[] = [
  {
    name: "Library",
    icon: <CategoryOutlinedIcon htmlColor={Colors.WHITE} />,
    section: DesignEditLeftSection.LIBRARY,
  },
  {
    name: "Text",
    icon: <TextFieldsIcon htmlColor={Colors.WHITE} />,
    section: DesignEditLeftSection.TEXT,
  },
  {
    name: "Upload",
    icon: <IosShareIcon htmlColor={Colors.WHITE} />,
    section: DesignEditLeftSection.UPLOAD,
  },
];

interface CustomDesignEditPopupSectionProps {
  design?: DesignDto;
  instantPreview?: InviteInstantPreview;
  selectedLayers: CanvasElement[];
  section?: DesignEditLeftSection;
}

function CustomDesignEditPopupSection({
  design,
  section,
  instantPreview,
  selectedLayers,
}: CustomDesignEditPopupSectionProps) {
  const onAdd = useNewLibraryImage(design);
  switch (section) {
    case DesignEditLeftSection.TEXT:
      return (
        <DesignEditTextSection
          instantPreview={instantPreview}
          selectedLayers={selectedLayers}
        />
      );
    case DesignEditLeftSection.LIBRARY:
      return (
        <DesignEditLibrarySection
          instantPreview={instantPreview}
          design={design}
          onAdd={onAdd}
        />
      );
    case DesignEditLeftSection.UPLOAD:
      return <DesignEditUploadSection instantPreview={instantPreview} />;
    default:
      return null;
  }
}

interface CustomDesignEditLeftSidebarProps {
  design?: DesignDto;
  instantPreview?: InviteInstantPreview;
  selectedLayers: CanvasElement[];
}

export default function CustomDesignEditLeftSidebar({
  design,
  instantPreview,
  selectedLayers,
}: CustomDesignEditLeftSidebarProps) {
  const [choosenSection, setChoosenSection] = useState<DesignEditLeftSection>();

  const openSection = useCallback((section?: DesignEditLeftSection) => {
    setChoosenSection(section);
  }, []);

  useEffect(() => {
    const textLayers = InviteUtils.getTextLayers(selectedLayers);
    if (textLayers.length > 0) {
      openSection(DesignEditLeftSection.TEXT);
    } else if (selectedLayers.length === 0) {
      openSection();
    }
  }, [selectedLayers, openSection]);

  return (
    <DesignEditLeftSidebar
      section={choosenSection}
      setSection={openSection}
      tabs={LeftTabs}
    >
      <CustomDesignEditPopupSection
        design={design}
        section={choosenSection}
        instantPreview={instantPreview}
        selectedLayers={selectedLayers}
      />
    </DesignEditLeftSidebar>
  );
}
