import { Box, BoxProps } from "@mui/system";

export default function AuthLayout({ children, ...props }: BoxProps) {
  return (
    <Box
      padding={2}
      minHeight={"100vh"}
      display="flex"
      flexDirection="column"
      {...props}
    >
      {children}
    </Box>
  );
}
