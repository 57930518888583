import Column from "./Column";
import FileUtils from "@paperdateco/shared-frontend/utils/FileUtils";
import Papa from "papaparse";
import get from "lodash.get";

export default class TableUtils {
  static exportCsv<RowData extends {}>(
    rows: RowData[],
    columns: Column<RowData>[],
    fileName: string = "export.csv"
  ) {
    return () => {
      const csvData = Papa.unparse({
        fields: columns.map((column) => column.title),
        data: rows.map((row) =>
          columns.map((column) =>
            column.render
              ? column.render(row, "row")
              : get(row, column.field) ?? ""
          )
        ),
      });
      FileUtils.saveCsvFile(csvData, fileName);
    };
  }
}
