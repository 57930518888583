import UserModel from "../../user/UserModel";

export default class CollaboratorDto {
  id: string;
  name: string;
  profilePicture?: string;
  bannerImage?: string;

  constructor(user: UserModel, populated = true) {
    if (!populated) {
      this.id = user._id.toString();
      return;
    }
    this.id = user.id;
    this.name = user.name;
    this.profilePicture = user.profilePicture;
    this.bannerImage = user.bannerImage;
  }
}
