import {
  Button,
  Link as ExternalLink,
  Typography,
  styled,
} from "@mui/material";
import { FormEvent, useContext, useState } from "react";

import { AuthContext } from "@paperdateco/shared-frontend/components/auth/AuthProvider";
import AuthForm from "./AuthForm";
import CustomTextField from "../common/form/CustomTextField";
import FormRow from "@paperdateco/shared-frontend/components/common/form/FormRow";
import GoogleButton from "../../assets/images/btn_google_signin.png";
import NotificationUtils from "@paperdateco/common/utils/NotificationUtils";
import UserApi from "@paperdateco/common/api/UserApi";
import UserDto from "@paperdateco/common/dto/user/UserDto";

const GoogleBtn = styled("img")({
  maxWidth: 250,
});

interface LoginFormProps {
  title: string;
  onLogin?: (user: UserDto) => void;
}

export default function LoginForm({ title, onLogin }: LoginFormProps) {
  const { updateAuth } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const user = await UserApi.login({
        email: email.toLowerCase(),
        password,
      });
      await updateAuth();
      onLogin?.(user);
    } catch (e) {
      NotificationUtils.showGenericError(e);
    }
  };

  return (
    <AuthForm>
      <Typography variant="h5" textAlign="center">
        {title}
      </Typography>
      <form onSubmit={handleSubmit}>
        <FormRow>
          <CustomTextField
            variant="standard"
            label="Email Address"
            type="email"
            name="email"
            value={email}
            onChange={setEmail}
          />
        </FormRow>

        <FormRow>
          <CustomTextField
            variant="standard"
            label="Password"
            type="password"
            name="password"
            autoComplete="password"
            value={password}
            onChange={setPassword}
          />
        </FormRow>

        <FormRow textAlign="center">
          <Button variant="contained" type="submit" size="large">
            LOGIN
          </Button>
          <Typography variant="body1" margin={1}>
            Or
          </Typography>
          <ExternalLink
            href={
              process.env.REACT_APP_API_URL +
              "auth/google?redirectTo=" +
              window.location.href
            }
          >
            <GoogleBtn src={GoogleButton} alt="Google Sign In Button" />
          </ExternalLink>
        </FormRow>
      </form>
    </AuthForm>
  );
}
