import DesignImageComponentDto from "../../../components/image/DesignImageComponentDto";
import DesignImageType from "../../image/DesignImageType";
import DesignMaskedImageProperties from "./DesignMaskedImageProperties";

export default class DesignMaskedImagePropertiesDto {
  image: DesignImageComponentDto;
  type: DesignImageType;
  offsetX?: number;
  offsetY?: number;
  scaleX?: number;
  scaleY?: number;

  constructor(maskedImage: DesignMaskedImageProperties) {
    this.image = new DesignImageComponentDto(maskedImage.image);
    this.type = maskedImage.type;
    this.offsetX = maskedImage.offsetX;
    this.offsetY = maskedImage.offsetY;
    this.scaleX = maskedImage.scaleX;
    this.scaleY = maskedImage.scaleY;
  }
}
