import { Button, Stack } from "@mui/material";
import CustomCheckboxGroup, {
  CustomCheckboxOptionType,
} from "@paperdateco/shared-frontend/components/common/form/CustomCheckboxGroup";
import { FormEvent, useCallback, useEffect, useState } from "react";

import CustomAutocomplete from "@paperdateco/shared-frontend/components/common/form/CustomAutoComplete";
import CustomCheckbox from "@paperdateco/shared-frontend/components/common/form/CustomCheckbox";
import CustomMediaPicker from "@paperdateco/shared-frontend/components/common/form/CustomMediaPicker";
import CustomNumberInput from "@paperdateco/shared-frontend/components/common/form/CustomNumberInput";
import CustomTextField from "@paperdateco/shared-frontend/components/common/form/CustomTextField";
import DesignApi from "@paperdateco/common/api/DesignApi";
import DesignDto from "@paperdateco/common/dto/design/DesignDto";
import DesignLibraryApi from "@paperdateco/common/api/design/DesignLibraryApi";
import FormContainer from "@paperdateco/shared-frontend/components/common/form/FormContainer";
import FormRow from "@paperdateco/shared-frontend/components/common/form/FormRow";
import LibraryImageDto from "@paperdateco/common/dto/design/library/image/LibraryImageDto";
import LibraryImageRequestDto from "@paperdateco/common/dto/design/library/image/LibraryImageRequestDto";
import LibraryImageRestriction from "@paperdateco/common/dto/design/library/image/LibraryImageRestriction";
import LibraryImageType from "@paperdateco/common/dto/design/library/image/LibraryImageType";
import NotificationUtils from "@paperdateco/common/utils/NotificationUtils";

const LibraryImageTypeOptions: CustomCheckboxOptionType<LibraryImageType>[] = [
  { name: "Background", value: LibraryImageType.BACKGROUND },
  { name: "Sticker", value: LibraryImageType.STICKER },
  { name: "GIF", value: LibraryImageType.GIF },
  { name: "Mask", value: LibraryImageType.MASK },
  { name: "Photo", value: LibraryImageType.PHOTO },
  { name: "Back Envelope", value: LibraryImageType.BACK_ENVELOPE },
  { name: "Cover Envelope", value: LibraryImageType.COVER_ENVELOPE },
  { name: "Front Envelope", value: LibraryImageType.FRONT_ENVELOPE },
];

interface LibraryImageFormProps {
  isAdmin?: boolean;
  libraryImage?: LibraryImageDto;
  defaultTypes?: LibraryImageType[];
  designs: DesignDto[];
  defaultDesign?: DesignDto;
  onSubmit?: (libraryImage: LibraryImageDto) => void;
}

export default function LibraryImageForm({
  isAdmin,
  libraryImage,
  defaultTypes,
  designs,
  defaultDesign,
  onSubmit,
}: LibraryImageFormProps) {
  const [name, setName] = useState("");
  const [types, setTypes] = useState<LibraryImageType[]>([]);
  const [url, setUrl] = useState<string>();
  const [price, setPrice] = useState(0);
  const [tags, setTags] = useState<string[]>([]);
  const [design, setDesign] = useState<string>();
  const [restrictToDesign, setRestrictToDesign] = useState(false);

  useEffect(() => {
    setName(libraryImage?.name ?? "");
    setTypes(libraryImage?.types ?? []);
    setUrl(libraryImage?.url ?? "");
    setPrice(libraryImage?.price ?? 0);
    setTags(libraryImage?.tags ?? []);
    setDesign(libraryImage?.design?.id);
    setRestrictToDesign(
      libraryImage?.restriction === LibraryImageRestriction.DESIGN
    );
  }, [libraryImage]);

  useEffect(() => {
    if (defaultDesign) {
      setDesign(defaultDesign.id);
    }
  }, [defaultDesign]);

  const onUpload = useCallback(async (file: File) => {
    const url = await DesignApi.uploadMedia(file);
    setUrl(url);
  }, []);

  const onSubmitForm = async (e: FormEvent) => {
    e.preventDefault();
    if (!url) {
      NotificationUtils.showError("Please upload a file");
      return;
    }
    const request: LibraryImageRequestDto = {
      name,
      types: defaultTypes ?? types,
      url,
      price,
      tags,
      design,
      restriction: restrictToDesign
        ? LibraryImageRestriction.DESIGN
        : LibraryImageRestriction.GLOBAL,
    };
    try {
      const newLibraryImage = libraryImage?.id
        ? await DesignLibraryApi.updateLibraryImage(libraryImage.id, request)
        : await DesignLibraryApi.addNewLibraryImage(request);
      onSubmit?.(newLibraryImage);
    } catch (e) {
      NotificationUtils.showGenericError(e);
    }
  };

  const onHideImage = () => {
    if (!libraryImage) {
      return;
    }
    DesignLibraryApi.hideLibraryImage(libraryImage.id)
      .then(onSubmit)
      .catch(NotificationUtils.showGenericError);
  };

  const designOptions = designs.map((design) => design.id);
  const designLabel = useCallback(
    (id: string) => designs.find((d) => d.id === id)?.name ?? "",
    [designs]
  );

  return (
    <FormContainer onSubmit={onSubmitForm}>
      <FormRow>
        <CustomTextField value={name} onChange={setName} label="Name" />
      </FormRow>
      {!defaultTypes && (
        <FormRow>
          <CustomCheckboxGroup
            label="Categories"
            options={LibraryImageTypeOptions}
            value={types}
            onChange={setTypes}
          />
        </FormRow>
      )}
      <FormRow display="flex" alignItems="center">
        <CustomMediaPicker url={url} onUpload={onUpload} />
      </FormRow>
      {!defaultDesign && (
        <FormRow>
          <CustomAutocomplete
            options={designOptions}
            label="Design"
            getOptionLabel={designLabel}
            value={design}
            onChange={setDesign}
          />
        </FormRow>
      )}
      {design && (
        <FormRow>
          <CustomCheckbox
            label="Restrict to Design"
            checked={restrictToDesign}
            onChange={setRestrictToDesign}
          />
        </FormRow>
      )}

      {isAdmin && (
        <>
          <FormRow>
            <CustomNumberInput
              value={price}
              onChange={setPrice}
              label="Price"
              inputProps={{ step: 0.01 }}
            />
          </FormRow>

          <FormRow>
            <CustomAutocomplete
              options={[]}
              label="Tags"
              value={tags}
              onChange={setTags}
              multiple
              freeSolo
            />
          </FormRow>
        </>
      )}
      <FormRow textAlign="center">
        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Button variant="contained" type="submit" size="large">
            {libraryImage ? "UPDATE" : "ADD"}
          </Button>

          {isAdmin && libraryImage?.visible && (
            <Button
              variant="contained"
              color="error"
              type="button"
              size="large"
              onClick={onHideImage}
            >
              HIDE
            </Button>
          )}
        </Stack>
      </FormRow>
    </FormContainer>
  );
}
