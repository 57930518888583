import DesignDto from "../../DesignDto";
import LibraryImageModel from "./LibraryImageModel";
import LibraryImageRestriction from "./LibraryImageRestriction";
import LibraryImageType from "./LibraryImageType";

export default class LibraryImageDto {
  id: string;
  name: string;
  types: LibraryImageType[];
  url: string;
  price: number;
  tags: string[];
  design?: DesignDto;
  restriction: LibraryImageRestriction;
  visible: boolean;

  constructor(item: LibraryImageModel) {
    if (typeof item.id !== "string") {
      this.id = item._id.toString();
      return;
    }
    this.id = item.id;
    this.name = item.name;
    this.types = item.types;
    this.url = item.url;
    this.price = item.price;
    this.tags = item.tags;
    this.design =
      item.design &&
      new DesignDto(item.design, item.populated("design") ?? false);
    this.restriction = item.restriction;
    this.visible = item.visible;
  }
}
