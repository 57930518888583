import { BoxProps } from "@mui/system";
import DesignApi from "@paperdateco/common/api/DesignApi";
import DesignDto from "@paperdateco/common/dto/design/DesignDto";
import DesignForm from "@paperdateco/shared-frontend/components/design/DesignForm";
import DesignRequestDto from "@paperdateco/common/dto/design/DesignRequestDto";
import NotificationUtils from "@paperdateco/common/utils/NotificationUtils";
import { useCallback } from "react";

interface DesignAddPageProps {
  onAdd?: (design: DesignDto) => void;
}

export default function DesignAddPage({
  onAdd,
  ...props
}: DesignAddPageProps & BoxProps) {
  const onAddDesign = useCallback(
    async (newDesignRequest: DesignRequestDto) => {
      try {
        const newDesign = await DesignApi.addNewDesigns(newDesignRequest);
        onAdd?.(newDesign);
      } catch (e) {
        NotificationUtils.showGenericError(e);
      }
    },
    [onAdd]
  );

  return <DesignForm onSave={onAddDesign} {...props} />;
}
