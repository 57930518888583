import AddNewButton from "@paperdateco/shared-frontend/components/common/form/button/AddNewButton";
import { Box } from "@mui/material";
import DashboardContentLayout from "@paperdateco/shared-frontend/components/layout/DashboardContentLayout";
import DesignListTable from "../../table/DesignListTable";
import routes from "@paperdateco/collaborator/routes/routes";

export default function DesignsListPage() {
  return (
    <DashboardContentLayout width="100%">
      <AddNewButton position="right" link={routes.DESIGN_ADD} name="DESIGN" />
      <Box marginTop={2}>
        <DesignListTable />
      </Box>
    </DashboardContentLayout>
  );
}
