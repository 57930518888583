import DesignLayerProperties, {
  OriginXTypes,
  OriginYTypes,
} from "./DesignLayerProperties";

import DesignAnimationComponentDto from "../../components/animation/DesignAnimationComponentDto";

export default class DesignLayerPropertiesDto {
  left: number;
  top: number;
  angle?: number;
  originX?: OriginXTypes;
  originY?: OriginYTypes;
  opacity?: number;
  scaleX?: number;
  scaleY?: number;
  flipX?: boolean;
  flipY?: boolean;
  animation?: DesignAnimationComponentDto;

  constructor(layer: DesignLayerProperties) {
    this.left = layer.left;
    this.top = layer.top;
    this.angle = layer.angle;
    this.originX = layer.originX;
    this.originY = layer.originY;
    this.scaleX = layer.scaleX;
    this.scaleY = layer.scaleY;
    this.opacity = layer.opacity;
    this.flipX = layer.flipX;
    this.flipY = layer.flipY;
    this.animation =
      layer.animation && new DesignAnimationComponentDto(layer.animation);
  }
}
