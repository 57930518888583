import {
  DesignStateDispatchType,
  getAllDesigns,
} from "@paperdateco/collaborator/store/design/DesignActions";

import DesignAddPage from "@paperdateco/shared-frontend/pages/design/DesignAddPage";
import DesignDto from "@paperdateco/common/dto/design/DesignDto";
import { getAllLibraryImages } from "@paperdateco/collaborator/store/libraryImage/LibraryImageAction";
import routes from "@paperdateco/collaborator/routes/routes";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import useListenLibraryUpdate from "@paperdateco/shared-frontend/components/design/section/library/useListenLibraryUpdate";
import { useNavigate } from "react-router-dom";

export default function CollaboratorDesignAddPage() {
  const dispatch = useDispatch<DesignStateDispatchType>();
  const navigate = useNavigate();
  const onAddNewDesign = useCallback(
    (design: DesignDto) => {
      dispatch(getAllDesigns(true));
      navigate(routes.DESIGN_EDIT(design.id));
    },
    [dispatch, navigate]
  );

  const onUpdateLibrary = useCallback(() => {
    dispatch(getAllLibraryImages(true));
  }, [dispatch]);

  useListenLibraryUpdate(onUpdateLibrary);

  return <DesignAddPage onAdd={onAddNewDesign} />;
}
