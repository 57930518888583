import DesignTextProperties, {
  FontStyleTypes,
  FontWeightTypes,
} from "./DesignTextProperties";

import DesignLayerPropertiesDto from "../common/DesignLayerPropertiesDto";
import TextPaths from "../paths/TextPaths";

export default class DesignTextPropertiesDto extends DesignLayerPropertiesDto {
  text: string;

  fontColor?: string;
  fontSize?: number;
  fontFamily?: string;
  fontWeight?: FontWeightTypes;
  fontStyle?: FontStyleTypes;
  underline?: boolean;
  letterSpacing?: number;
  lineSpacing?: number;
  textAlign?: string;
  textPath?: TextPaths;
  textPathParams?: number[];

  constructor(text: DesignTextProperties) {
    super(text);
    this.text = text.text;
    this.fontColor = text.fontColor;
    this.fontSize = text.fontSize;
    this.fontFamily = text.fontFamily;
    this.fontWeight = text.fontWeight;
    this.fontStyle = text.fontStyle;
    this.underline = text.underline;
    this.letterSpacing = text.letterSpacing;
    this.lineSpacing = text.lineSpacing;
    this.textAlign = text.textAlign;
    this.textPath = text.textPath;
    this.textPathParams = text.textPathParams;
  }
}
