import DesignImageComponentDto from "../../components/image/DesignImageComponentDto";
import DesignImageProperties from "./DesignImageProperties";
import DesignImageType from "./DesignImageType";
import DesignLayerPropertiesDto from "../common/DesignLayerPropertiesDto";

export default class DesignImagePropertiesDto extends DesignLayerPropertiesDto {
  image: DesignImageComponentDto;
  type: DesignImageType;
  width: number;
  height: number;

  constructor(image: DesignImageProperties) {
    super(image);
    this.image = new DesignImageComponentDto(image.image);
    this.type = image.type;
    this.width = image.width;
    this.height = image.height;
  }
}
